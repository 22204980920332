import React, { useState } from 'react';
import { MessageDialog } from '@entio/react-components';
import { UserDataResult } from '@entio/accounts-sdk';
import { LoginButton, LoginHandler, setOnValidateSession } from '@entio/accounts-react-sdk';

import { useStateValue } from '../../contexts/StateContext';
import { loginSession } from '../../actions/authAction';

const Login = () => {
  const [, dispatch] = useStateValue();
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [messageDialog, setMessageDialog] = useState('');

  const onSuccess = async (data: UserDataResult) => {
    console.log(data);
    const { user: userData, profile, token } = data;
    dispatch(loginSession({ user: userData, profile, token }));
    setOnValidateSession(() => true);
  };

  const onError = async (error: string) => {
    setOpenMessageDialog(true);
    setMessageDialog(error);
  };

  return (
    <LoginHandler onSuccess={onSuccess} onError={onError}>
      <div>
        <MessageDialog
          open={openMessageDialog}
          message={messageDialog}
          mainButtonText={'Cerrar'}
          onMainButtonClick={() => {
            setOpenMessageDialog(false);
            setMessageDialog('');
          }}
        />
        <LoginButton quick />
      </div>
    </LoginHandler>
  );
};

export default Login;
